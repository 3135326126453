import React, { useRef, useState } from 'react';
import { graphql } from "gatsby"
import { makeStyles } from '@material-ui/core/styles';

import useMatchedQuery from '../hooks/useMatchedQuery';

import GamesLayout from '../components/GamesLayout';
import SEO from '../components/SEO';
import SearchContext from '../components/TopBarGames/context';
import GameControlNavigator from '../components/GameControlNavigator2'


const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    justifyContent: 'center',
    backgroundColor: theme.palette.background.paper,
  },
  scroller: {
    height: '100vh',
    width: '100%',
    overflowX: 'hidden',
    boxSizing: 'content-box',
  },
  loading: {
    // bottom: 0,
    // position: 'absolute',
    padding: theme.spacing(2),
    margin: theme.spacing(1),
  }
}));

const Spacer = () => {
  const NAVIGATION_HEIGHT = 32;
  return (
    <div style={{ height: NAVIGATION_HEIGHT, content: '' }} />
  )
};

const Page = (props) => {
  const { data, pageContext } = props;
  const { pageUrl } = pageContext;
  const { tag, seo, site, games: gameNodes } = data
  const items = gameNodes.edges.map((t) => t.node)
  const games = items
  const totalCount = gameNodes.edges.length;
  const matchedQuery = useMatchedQuery()
  const scroller = useRef();
  const classes = useStyles();
  const [db, setDb] = useState(items);

  const defaultDescription = `Conocé los ${games.length} juegos etiquetados con ${tag.title.toUpperCase()} de nuestro catálogo.`
  return (
    <>
      <SEO {...seo} {...pageContext} description={tag.description && tag.description.raw || defaultDescription} pathName={pageUrl} seo={{...seo}} siteUrl={site.siteMetadata.siteUrl}/>
      <GamesLayout {...pageContext} items={items} title={` ${tag.title}`} staticSearch={true} displayDataFiscal={false} displayContact={false} >
        <Spacer />
        <div ref={scroller} className={classes.scroller} style={{ height: props.height, width: props.width || '100%' }} >
          <SearchContext.Consumer>
            {search => {
              const searchResult = {
                games: search && search.searchResult || db,
                totalCount: search && search.searchResult && search.searchResult.length || totalCount,
              }
              return (
              <React.Fragment>
                <GameControlNavigator height={'100vh'} preloaded={searchResult} matchedQuery={matchedQuery} />
              </React.Fragment>
            )
            }}
          </SearchContext.Consumer>
        </div>
      </GamesLayout>
    </>
  )
}

export default Page;

export const query = graphql`
  query($tagId: String) {
    seo: contentfulComponentSeo(name: {eq: "games"}, node_locale: {eq: "es-AR"}) {
      title
      description {
        description
      }
      image {
        gatsbyImageData(
          layout: FIXED,
          width: 600,
          height: 600
        )
        id
        title
      }
      name
    }
    site: site {
      siteMetadata {
        siteUrl
      }
    }
    tag: contentfulGameTag(
      contentful_id: {eq: $tagId}
      node_locale: {eq: "es-AR"}
    ) {
      slug
      id
      contentful_id
      title: name
      description {
        raw
      }
      contentful_id
    }
    games: allContentfulGame(
      sort: {fields: title, order: ASC}
      filter: {
        gameTags: {
          elemMatch: {
            contentful_id: {eq: $tagId}
          }
        },  
        node_locale: {eq: "es-AR"}, 
        expansion: {eq: false},
        listed: {ne: false}
      }
    ) {
      totalCount
      edges {
        node {
          ...GameIndexEntry
        }
      }
    }
     
  }
`
