import React, {useState, useRef, useEffect, useContext, useCallback} from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import { useLocation } from "@reach/router";
import BackToTop from '../BackToTop';
import SearchContext from '../TopBarGames/context';
import useGamesForSaleState from '../../hooks/useGamesForSaleState';
import { VirtuosoCardGenerator } from '../GameCard';
import Navigator from '../Navigator';

const DEFAULT_SPACING = 2;

const cardHeights = {
  xs: 530,
  sm: 530,
  md: 530,
  lg: 520,
  xl: 520,
}

const miniCardHeights = {
  xs: 64,
  sm: 90,
  md: 121,
  lg: 121,
  xl: 121,
  // xs: 144,
  // sm: 160,
  // md: 242,
  // lg: 242,
  // xl: 242,

}

const breakpoints = {
  xs: 0,
  sm: 600,
  md: 960,
  lg: 1280,
  xl: 1920,
}

const gridOptions = {
  xs: 6,
  sm: 4,
  md: 3,
  lg: 3,
  xl: 3,
}
export const cardWidth = {
  fallback: {
    minWidth: 144, 
    maxWidth: 360,
  },
  xs: {
    minWidth: 144, 
    maxWidth: 360,
  },
  sm: {
    minWidth: 160,
    maxWidth: 160,
  },
  md: {
    minWidth: 242,
    maxWidth: 242,
  },
  lg: {
    minWidth: 242,
    maxWidth: 242,
  },
  xl: {
    minWidth: 242,
    maxWidth: 242,
  },
}

const miniCardWidth = {
  fallback: {
    minWidth: 64, 
    maxWidth: 150,
  },
  sm: {
    minWidth: 80,
    maxWidth: 121,
  },
  md: {
    minWidth: 121,
    maxWidth: 121,
  },
  lg: {
    minWidth: 121,
    maxWidth: 121,
  },
  xl: {
    minWidth: 121,
    maxWidth: 121,
  },
}


const imageContainerWidth = {
  xs: {
    minWidth: 172,
    maxWidth: 300,
  },
  sm: {
    minWidth: 172,
    maxWidth: 300,
  },
  md: {
    minWidth: 242,
    maxWidth: 300,
  },
  lg: {
    minWidth: 242,
    maxWidth: 300,
  },
  xl: {
    minWidth: 242,
    maxWidth: 300,
  },
}

const miniImageContainerWidth = {
  xs: {
    minWidth: 64,
    maxWidth: 150,
  },
  sm: {
    minWidth: 86,
    maxWidth: 150,
  },
  md: {
    minWidth: 121,
    maxWidth: 150,
  },
  lg: {
    minWidth: 121,
    maxWidth: 150,
  },
  xl: {
    minWidth: 121,
    maxWidth: 150,
  },
}



export const gridStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    justifyContent: 'space-around',
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    width: '100%',
    height: '100%',
  },
  icon: {
    color: 'rgba(255, 255, 255, 0.54)',
  },
  gameCard: {
    height: cardHeights.sm - theme.spacing(DEFAULT_SPACING) - 1,
    maxWidth: cardWidth.fallback.maxWidth - theme.spacing(DEFAULT_SPACING) - 1,
    minWidth: cardWidth.fallback.minWidth - theme.spacing(DEFAULT_SPACING) - 1,
    [theme.breakpoints.up('sm')]: {
      height: cardHeights.sm - theme.spacing(DEFAULT_SPACING) - 1,
      maxWidth: cardWidth.sm.maxWidth - theme.spacing(DEFAULT_SPACING) - 1,
      minWidth: cardWidth.sm.minWidth - theme.spacing(DEFAULT_SPACING) - 1,
    },
    [theme.breakpoints.up('md')]: {
      height: cardHeights.md - theme.spacing(DEFAULT_SPACING) - 1,
      minWidth: cardWidth.md.minWidth,
    },
    [theme.breakpoints.up('lg')]: {
      height: cardHeights.lg - theme.spacing(DEFAULT_SPACING) - 1,
      minWidth: cardWidth.lg.minWidth,
    },
    [theme.breakpoints.up('xl')]: {
      height: cardHeights.xl - theme.spacing(DEFAULT_SPACING) - 1,
      minWidth: cardWidth.xl.minWidth,
    },
  },
  miniCard: {
    height: miniCardHeights.sm + theme.spacing(3.5),
    maxWidth: miniCardHeights.sm + theme.spacing(3.5),
    minWidth: miniCardWidth.fallback.minWidth,
    [theme.breakpoints.up('sm')]: {
      height: miniCardHeights.sm + theme.spacing(3),
      maxWidth: miniCardHeights.sm + theme.spacing(3),
      minWidth: miniCardWidth.sm.minWidth,
    },
    [theme.breakpoints.up('md')]: {
      height: miniCardHeights.md + theme.spacing(1),
      maxWidth: miniCardHeights.md + theme.spacing(1),
      minWidth: miniCardWidth.md.minWidth,
    },
    [theme.breakpoints.up('lg')]: {
      height: miniCardHeights.lg + theme.spacing(1),
      maxWidth: miniCardHeights.lg + theme.spacing(1),
      minWidth: miniCardWidth.lg.minWidth,
    },
    [theme.breakpoints.up('xl')]: {
      height: miniCardHeights.xl + theme.spacing(0.5),
      maxWidth: miniCardHeights.xl + theme.spacing(0.5),
      minWidth: miniCardWidth.xl.minWidth,
    },
  },
  cardContent: {
    padding: theme.spacing(1),
  },
  title: {
    height: (theme.typography.htmlFontSize * 1.5 * theme.typography.h5.lineHeight) * 4,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  bottom: {
    margin: theme.spacing(3),
    textAlign: 'center',
  },
  bottomPrice: {
    margin: theme.spacing(2),
  },
  priceBox: {
    padding: theme.spacing(0.5),
    backgroundColor: `${theme.palette.primary.main}10`,
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    }
    
    // padding: theme.spacing(2),
  },
  priceValue: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    borderRadius: theme.spacing(1),
    padding: theme.spacing(1),
  },
  priceText: {
    fontWeight: 600,
    textAlign: 'center',
  },
  loading: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  imageContainer: {
    position: 'relative',
    [theme.breakpoints.up('xs')]: {
      maxHeight: imageContainerWidth.xs.maxWidth, 
      minWidth: imageContainerWidth.xs.minWidth,
      maxWidth: imageContainerWidth.xs.maxWidth,
    },
    [theme.breakpoints.up('sm')]: {
      maxHeight: imageContainerWidth.sm.maxWidth,
      minWidth: imageContainerWidth.sm.minWidth,
      maxWidth: imageContainerWidth.sm.maxWidth,
    },
    [theme.breakpoints.up('md')]: {
      minWidth: imageContainerWidth.md.minWidth,
      maxWidth: imageContainerWidth.md.maxWidth,
      maxHeight: imageContainerWidth.md.maxWidth,
    },
    [theme.breakpoints.up('lg')]: {
      minWidth: imageContainerWidth.lg.minWidth,
      maxWidth: imageContainerWidth.lg.maxWidth,
      maxHeight: imageContainerWidth.lg.maxWidth,
    },
    [theme.breakpoints.up('xl')]: {
      minWidth: imageContainerWidth.xl.minWidth,
      maxWidth: imageContainerWidth.xl.maxWidth,
      maxHeight: imageContainerWidth.xl.maxWidth,
    },
    overflow: 'hidden',
  },
  miniImageContainer: {
    position: 'relative',
    [theme.breakpoints.up('xs')]: {
      minWidth: miniImageContainerWidth.xs.minWidth,
      maxWidth: miniImageContainerWidth.xs.maxWidth,
      maxHeight: miniImageContainerWidth.xs.maxWidth,
    },
    [theme.breakpoints.up('sm')]: {
      minWidth: miniImageContainerWidth.sm.minWidth,
      maxWidth: miniImageContainerWidth.sm.maxWidth,
      maxHeight: miniImageContainerWidth.sm.maxWidth,
    },
    [theme.breakpoints.up('md')]: {
      minWidth: miniImageContainerWidth.md.minWidth,
      maxWidth: miniImageContainerWidth.md.maxWidth,
      maxHeight: miniImageContainerWidth.md.maxWidth,
    },
    [theme.breakpoints.up('lg')]: {
      minWidth: miniImageContainerWidth.lg.minWidth,
      maxWidth: miniImageContainerWidth.lg.maxWidth,
      maxHeight: miniImageContainerWidth.lg.maxWidth,
    },
    [theme.breakpoints.up('xl')]: {
      minWidth: miniImageContainerWidth.xl.minWidth,
      maxWidth: miniImageContainerWidth.xl.maxWidth,
      maxHeight: miniImageContainerWidth.xl.maxWidth,
    },
    overflow: 'hidden',
  },
  availableGameImage: {
  },
  comingGameImage: {
    WebkitFilter: 'grayscale(1)',
    filter: 'grayscale(1)',
  },
  labels: {
    zIndex: 400,
    position: 'absolute',
    display: 'block',
    height: theme.typography.fontSize + (theme.spacing() * 2),
    width: '100%',
    bottom: 0,
    left: 0,
    // top: 144 - (theme.typography.fontSize + (theme.spacing() * 2)),
  },
  labelIcon: {
    // padding: theme.spacing() / 4,
  },
  labelText: {
    padding: theme.spacing() / 4,
    fontWeight: 600,
    textTransform: 'uppercase',
  },
  boxIcon: {
    position: 'absolute',
    right: 5,
    bottom: 5,
    fontSize: 46,
  },
  lastUnit: {
    position: 'absolute',
    display: 'block',
    backgroundColor: '#ffea00ed',
    color: '#333',
    width: '110%',
    bottom: 0,
    left: 0,
    textAlign: 'center',
    [theme.breakpoints.up('xl')]: {
      transform: 'rotate(-45deg) translate(80px,-180px)',
      webkitTransform: 'rotate(-45deg) translate(80px,-180px)',
      mozTransform: 'rotate(-45deg) translate(80px,-180px)',
    },
    [theme.breakpoints.only('lg')]: {
      transform: 'rotate(-45deg) translate(70px,-190px)',
      webkitTransform: 'rotate(-45deg) translate(70px,-190px)',
      mozTransform: 'rotate(-45deg) translate(70px,-190px)',
    },
    [theme.breakpoints.only('md')]: {
      transform: 'rotate(-45deg) translate(74px,-190px)',
      webkitTransform: 'rotate(-45deg) translate(74px,-190px)',
      mozTransform: 'rotate(-45deg) translate(74px,-190px)',
    },
    '@media (min-width: 740px) and (max-width: 959.95px)': {
      transform: 'rotate(-45deg) translate(84px,-160px)',
      webkitTransform: 'rotate(-45deg) translate(74px,-190px)',
      mozTransform: 'rotate(-45deg) translate(74px,-190px)',
    },
    '@media (min-width: 640px) and (max-width: 739.95px)': {
      transform: 'rotate(-45deg) translate(64px,-130px)',
      webkitTransform: 'rotate(-45deg) translate(74px,-190px)',
      mozTransform: 'rotate(-45deg) translate(74px,-190px)',
    },
    '@media (min-width: 600px) and (max-width: 639.95px)': {
      transform: 'rotate(-45deg) translate(54px,-120px)',
      webkitTransform: 'rotate(-45deg) translate(74px,-190px)',
      mozTransform: 'rotate(-45deg) translate(74px,-190px)',
    },
    '@media (min-width: 500px) and (max-width: 599.95px)': {
      transform: 'rotate(-45deg) translate(74px,-170px)',
      webkitTransform: 'rotate(-45deg) translate(74px,-190px)',
      mozTransform: 'rotate(-45deg) translate(74px,-190px)',
    },
    '@media (min-width: 400px) and (max-width: 499.95px)': {
      transform: 'rotate(-45deg) translate(54px,-140px)',
      webkitTransform: 'rotate(-45deg) translate(74px,-190px)',
      mozTransform: 'rotate(-45deg) translate(74px,-190px)',
    },
    // [theme.breakpoints.only('sm')]: {
    //   transform: 'rotate(-45deg) translate(64px,-150px)',
    //   webkitTransform: 'rotate(-45deg) translate(54px,-120px)',
    //   mozTransform: 'rotate(-45deg) translate(54px,-120px)',
    // },
    '@media (min-width:360px) and (max-width: 399.95px)': {
      transform: 'rotate(-45deg) translate(48px,-120px)',
      webkitTransform: 'rotate(-45deg) translate(48px,-120px)',
      mozTransform: 'rotate(-45deg) translate(48px,-120px)',
    },
    '@media (min-width:0px) and (max-width: 359.95px)': {
      transform: 'rotate(-45deg) translate(44px,-100px)',
      webkitTransform: 'rotate(-45deg) translate(44px,-90px)',
      mozTransform: 'rotate(-45deg) translate(44px,-90px)',
    },
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(),
    // color: theme.palette.getContrastText(bgColor(theme, 'ee')[type]),
    // backgroundColor: bgColor(theme, 'ee')[type],
    height: theme.typography.fontSize + (theme.spacing() * 2),
    width: '100%',
  },
  imagePlaceHolder: {
    backgroundColor: theme.palette.background.paper,
    width: '100%',
    height: '100%',
  }
}));



const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    justifyContent: 'center',
    backgroundColor: theme.palette.background.paper,
  },
  scroller: {
    height: '100vh',
    width: '100%',
    overflowX: 'hidden',
    boxSizing: 'content-box',
  },
  loading: {
    // bottom: 0,
    // position: 'absolute',
    padding: theme.spacing(2),
    margin: theme.spacing(1),
  }
}));


const LoadingStatus = ({message}) => {
  const classes = useStyles();
  return (
    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
      <Paper elevation={3} className={classes.loading}>
        <Typography variant="h5" component="p" align="center">{message}</Typography>
      </Paper>
    </Grid>
  )
}

const GameControl = (props) => {
  const classes = useStyles();
  const gridClasses = gridStyles();
  const city = props.city;
  const location = useLocation();
  const scroller = useRef();
  const search = useContext(SearchContext);
  const [db, setDb] = useState(props.preloaded.games);
  const [dbLoaded, setDbLoaded] = useState(true);
  const [initialItem, setInitialItem] = useState();
  const totalItems = useRef(props.preloaded.totalCount);
  useEffect(() => {
    if (initialItem === undefined) {
      const urlParams = new URLSearchParams(location.search);
      const start = parseInt(urlParams.get('start'), 10) || 0;
      setInitialItem(start)
    }
  }, [location.search, initialItem])

  useEffect(() => {
    if (initialItem === 0 && search && search.needsSearch === false) {
      setDb(props.preloaded.games)
    }
  }, [initialItem, search, props.preloaded.games])

  useEffect(() => {
    if (search && search.searchResult) {
      setDb(search.searchResult);
      totalItems.current = search.searchResult.length;
      setDbLoaded(true)
    } else if (search && search.needsSearch === false) {
      setDb(search.searchIndex);
      totalItems.current = search.searchIndex.length;
      setDbLoaded(true)
    }
  }, [search]);

  const { gameForSaleInfo, liveInfoLoaded } = useGamesForSaleState();

  const virtuosoCardProps = {
    city,
    gameForSaleInfo,
    liveInfoLoaded,
    gridClasses,
    gridOptions,
    matchedQuery: props.matchedQuery,
  }
  const VirtuosoCard = VirtuosoCardGenerator(search && search.searchResult || db, virtuosoCardProps)

  return (
    <Grid container spacing={DEFAULT_SPACING} className={classes.root}>
      <div ref={scroller} className={classes.scroller} style={{ height: props.height, width: props.width || '100%' }} >
        <SearchContext.Consumer>
          {search => {
            const ready = (search && search.preSearchDone) || (db && search && search.needsSearch && search.preSearchDone) || (db && !search || !search.needsSearch)
            {/* console.log('search', search)
            console.log('ready', ready)
            console.log('ready 1', db && search && search.needsSearch && search.preSearchDone)
            console.log('ready 2', db && !search || !search.needsSearch) */}
            return (
            <React.Fragment>
              {ready && <Navigator
                initialItem={initialItem}
                cardHeights={cardHeights}
                generate={VirtuosoCard}
                height={props.height}
                width={props.width}
                breakpoints={breakpoints}
                gridOptions={gridOptions}
                totalItems={totalItems.current}
                city={props.city}
                dbLoaded={dbLoaded}
                matchedQuery={props.matchedQuery}
              />}
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <BackToTop scroller={scroller} querySelector={["#games-start-0", "#back-to-top-anchor"]} />
              </Grid>
            </React.Fragment>
          )}
          }
        </SearchContext.Consumer>
      </div>
    </Grid>
  );
};

export default GameControl;
